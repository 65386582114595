/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */

$color-pink: #CC257A;
$white-bg: #fff;
$black-color: #554E4E;
$section-padding: 5em 0px 8em;
$section-padding-mobile: 0em 0px 8em;
$dark-black: #000;
$white-color: #fff;
$red-color: #FD3F3F;
$color-grey: #4A4A4A;
$color-light-grey: #98A2B3;
$light-grey-bg: #f5f5f5;
@import 'bootstrap/scss/bootstrap';
@import 'breakpoints.scss';

html {
    width: 100%;
    height: 100%;
}

body {
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    background-color: $white-bg;
    width: 100%;
    overflow-x: hidden;

    @include lg {
        font-size: 0.875em
    }

    @include md {
        font-size: 0.8125em;
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background-color: #F1F2F3;
    border-radius: 0.625em;
}

::-webkit-scrollbar-thumb {
    background: #DFDFDF;
    border-radius: 0.625em;
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: $color-light-grey;
}

ul {
    list-style: none;

    li {
        list-style: none;
    }
}

/*Common Css*/

/*Heading CSS Start*/
@mixin heading {
    font-size: 1.5em;
    color: $black-color;
    position: relative;
    font-weight: 500;
    width: auto;
    margin-bottom: 2.375em;
    display: table;
    text-transform: uppercase;

    &::before {
        position: absolute;
        content: '';
        display: block;
        height: 0.0625em;
        width: 100%;
        bottom: -25px;
        border: 0.0625em solid $black-color;
    }

    &::after {
        position: absolute;
        content: '';
        display: block;
        height: 0.0625em;
        width: 100%;
        bottom: -0.625em;
        border: 0.0625em dashed $black-color;
    }
}

h1 {
    font-size: 2.3em;
    color: $black-color;
    position: relative;
    margin: 0 auto;
    display: table;
    font-weight: 500;
    width: auto;
    margin-bottom: 2.375em;
    text-align: center;
    line-height: 60px;


    @include sm {
        font-size: 1.8em;
    }

    @media (max-width: 1300px) {
        font-size: 30px;
    }

    @include lg {
        font-size: 1.75em;
    }

    &::before {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        bottom: -15px;
        border: 1.8px solid $black-color;
        margin-bottom: 20px;

        @media (max-width: 990px) {
            bottom: -15px;
        }

    }

    &::after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        bottom: -25px;
        border: 1.8px dashed $black-color;
        margin-bottom: 20px;

    }
}

.modal-header {
    h1 {
        &::before {
            content: '';
            margin-bottom: 0px;
        }

        &::after {
            content: '';
            margin-bottom: 0px;
        }
    }

    .btn-close {
        margin-left: 0 !important;
    }
}

/*Heading CSS END*/

.card {
    border-radius: 0.125em;
    background: $white-bg;
    box-shadow: 0.125em 0.25em 2.25em 0px #EEE;
    border: 0em
}

/*Banner Design Start*/

.banner {
    position: relative;

    h2 {
        color: #9FEFF5;
        text-align: center;
        text-shadow: 0em 0.25em 0.25em rgba(0, 0, 0, 0.25);
        font-size: 8em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4em;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        text-transform: uppercase;
        margin: 0;


        @include xl {
            font-size: 6em
        }

        @include lg {
            font-size: 5em
        }

        @include md {
            font-size: 3em
        }

        @include sm {
            font-size: 2em;
        }
    }
}

/*Banner Design END*/


/*Home page Start*/

.main-content {
    padding: $section-padding;

    p {
        color: $black-color;
        text-align: center;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5em;
        margin-bottom: 1.875em;
    }

    img {
        margin-bottom: 0.625em;
    }

    h5 {
        color: $dark-black;
        text-align: center;
        font-size: 2em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
    }
}


/*Home page END*/


/*Common Footer Start*/

.footer {
    padding: $section-padding;
    padding-bottom: 3em;
    width: 100%;
    position: relative;
    background-image: url("assets/img/testimonial.svg");
    background-size: cover;
    z-index: 1;
    height: 650px;

    @media (max-width: 400px) {
        height: 590px;
    }

    @include sm {
        height: 585px;
    }

    &::before {
        position: absolute;
        display: block;
        background-image: radial-gradient(62.41% 62.41% at 49.97% 44.59%, rgba(0, 0, 0, 0.12) 47.72%, rgba(0, 0, 0, 0.91) 88.33%);
        content: '';
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .footer-text {
        color: $white-color;
        margin: 0em 0.3125em;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .carousel-control-prev {
        z-index: 9;
        left: -10%;

        @include sm {
            display: none;
        }
    }

    .carousel-control-next {
        z-index: 9;
        right: -10%;

        @include sm {
            display: none;
        }
    }

    .carousel-inner {
        max-width: 800px;

        @include sm {
            max-width: 300px;
        }
    }

    .carousel-indicators {
        margin-left: 15%;
        height: 40px;
    }

    .footer-logo {
        position: relative;
        margin: 0 auto 30px;

        &::before {
            position: absolute;
            top: 0px;
            background-image: url("assets/img/footer-log-1.svg");
            width: 85px;
            height: 209px;
            content: "";
            display: table;
            margin: 0 auto;
            top: -157px;
            left: 0;
            right: 0;
        }

    }

    h1 {
        color: $white-color;

        &::before {
            border: 0.0625em solid $white-color;
        }

        &::after {
            border: 0.0625em dashed $white-color;
        }
    }

    p {
        color: $white-color;
    }

    ::ng-deep .carousel-control-next-icon {
        border-radius: 11px;
        background: #CC257A url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: center;
    }

    ::ng-deep .carousel-control-prev-icon {
        border-radius: 11px;
        background: #CC257A url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: center;
    }

    .carousel-caption {
        position: relative;
        padding-top: 2.25em;
        padding-bottom: 2.25em;
        color: #fff;
        text-align: center;
        right: auto;
        bottom: auto;
        left: auto;

        h3 {
            font-size: 1.3em;
            color: #fff;
            font-weight: 400;
            line-height: 1.7em;
            text-overflow: ellipsis;
            height: 135px;
            position: relative;
            display: inline-block;
            overflow: hidden;
        }

        p {
            margin: 30px 0px;
            font-size: 1em;
            z-index: 99999;
            position: relative;
        }
    }

    a {
        cursor: pointer;
    }
}

.footer-bottom {
    background-color: black;
    padding: 1.875em 0em;

    .social-icon {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1.25em;
        padding: 0;

        @include lg {
            justify-content: center;
            margin: 2em 0 3em;
        }

        .white_box {
            background-color: #fff;
            width: 2.1875em;
            height: 2.1875em;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.375em;
        }
    }

    .footer-logo {
        position: relative;
        margin: 0 auto 1.875em;

        &::before {
            position: absolute;
            top: 0em;
            background-image: url("assets/img/footer-log-1.svg");
            width: 5.3125em;
            height: 13.0625em;
            content: "";
            display: table;
            margin: 0 auto;
            top: -157px;
            left: 0;
            right: 0;
        }
    }

    .footer-text {
        color: #fff;
        text-align: right;

        @include lg {
            text-align: center;
            margin: 3em 0;
        }

        @include sm {
            margin-top: 40px !important;
        }

        a {
            color: #696969;
        }
    }

    .reserve-right {
        p {
            color: $white-color;
            font-size: 16px;
            font-weight: 400;

            @media (max-width: 767px) {
                margin-bottom: 0;
                padding-top: 15px;
                margin: 0 auto;
                text-align: center;
                font-size: 14px;
            }
        }
    }
}


/*Common Footer End*/

/*******Session CSS Start******/

.event-list .session-textbox {
    .filter {
        position: absolute;
        top: 11px;
        right: 20px;

        i {
            color: #98A2B3;
        }

        @include sm {
            top: 5px;
        }
    }

    .filter-search {
        position: absolute;
        right: 10px;
        z-index: 999;
        background: #fff;
        padding: 15px 25px;
        box-shadow: 4px 4px 32px 0px #E4E4E7;

        .form-check {
            padding-left: 0;

            .form-check-input {
                display: flex;
                float: right;
                margin-left: 50px;
            }
        }

    }
}

.session-box {
    background-color: $light-grey-bg;
    padding: 1.25em;

    @include sm {
        margin: 10px;
    }

    h4 {
        font-size: 1.25em;
        font-weight: 600;
        color: $black-color;
        letter-spacing: 0.1875em;

        @include sm {
            font-size: 1em;
        }

        @include lg {
            font-size: 1.125em;
        }
    }

    .class-box {
        background-color: $light-grey-bg;
        padding: 1.375em;
        display: flex;

        @include lg {
            display: block !important;
        }

        h4 {
            font-size: 1em;
            font-weight: 600;
            color: $dark-black;
            letter-spacing: 0.0625em;
        }

        p {
            font-size: 0.875em;
            font-weight: 400;
            color: $color-grey;
            margin-bottom: 0.3125em;
            margin-top: 0.5em;

            i {
                color: $color-light-grey;
            }
        }

        span {
            color: $color-grey;
            font-size: 0.9375em;
            font-weight: 600;
        }

        label {
            background-color: $color-grey;
            font-size: 0.625em;
            font-weight: 400;
            border-radius: 0.625em;
            color: $white-color;
            padding: 1px 0.5em;
            margin-left: 0.3125em;
            margin-bottom: 3px;
        }

        .btn-primary {
            background-color: $color-pink;
            border-radius: 0;
            border: 1px solid transparent;
            padding: 0.625em 0.8125em;
            min-width: 90px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include sm {
                font-size: 1em !important;
            }

            a {
                color: $white-color;
                font-size: 0.875em;
                font-weight: 400;
                letter-spacing: 0.0625em;

                i {
                    font-size: 0.875em;
                }
            }

            &:hover {
                background-color: $white-bg;
                color: $color-pink;
                border-color: $color-pink;
                transition: 0.5s;
            }
        }
    }

    .view-more {
        a {
            color: $color-light-grey;
            font-size: 1em;
            font-weight: 600;
            margin-top: 0.625em;
        }
    }

    .session-textbox {
        position: relative;

        .custom-day {
            text-align: center;
            padding: 0.185rem 0.25rem;
            display: inline-block;
            height: 2rem;
            width: 2rem;
        }

        .custom-day.focused {
            background-color: #e6e6e6;
        }

        .custom-day.range,
        .custom-day:hover {
            background-color: rgb(2, 117, 216);
            color: $white-color;
        }

        .custom-day.faded {
            background-color: rgba(2, 117, 216, 0.5);
        }

        .session-search {

            input {
                padding: 0.625em 0.625em 0.625em 3em;
                border: none;
                font-size: 1em;
                font-weight: 400;
                color: $color-grey;

                @media (max-width: 576px) {
                    padding: 0.625em 0.625em 0.625em 3em;
                }

                @media (max-width: 950px) {
                    padding: 0.625em 0.625em 0.625em 3em;
                }

                @include sm {
                    margin-bottom: 0.625em;
                    padding: 0.625em 0.625em 0.625em 3em;
                }

                &:focus-visible {
                    outline: none;
                }

                &[type="date"]::-webkit-calendar-picker-indicator {
                    position: absolute;
                    left: 0.3125em;
                    filter: invert(0.5);

                    &::after {
                        content: attr(placeholder);
                    }
                }
            }

            i {
                position: absolute;
                left: 4px;
                padding-left: 0.625em;
                top: 13px;
                color: $color-light-grey;
                display: flex;
                align-items: center;
            }
        }
    }

    .accordion {
        background-color: transparent;

        .accordion-item {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border: none;
            padding: 0.625em;
            display: block;

            &:first-of-type,
            &:not(:first-of-type) {
                margin: 0.625em 0;
            }

            .accordion-button {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border: none;

                h5 {
                    text-transform: uppercase;
                    color: $black-color;
                    font-size: 1.125em;
                    font-weight: 600;
                    letter-spacing: 0.1875em;
                    display: block;

                    @include lg {
                        font-size: 1em;
                    }

                    p {
                        font-size: 0.75em;
                        font-weight: 400;
                        display: block;
                        margin-bottom: 0;
                        margin-top: 0.625em;
                    }
                }

                &:not(.collapsed) {
                    background-color: transparent;
                }

                &:focus {
                    box-shadow: none;
                }

                &:not(.collapsed)::after {
                    content: "\f068";
                    font-family: FontAwesome;
                    display: block;
                    background-image: none;
                    background-color: $color-light-grey;
                    border-radius: 50%;
                    color: $white-color;
                    padding: 0.0625em 0.1875em;
                }

                &::after {
                    content: "\f067";
                    font-family: FontAwesome;
                    display: block;
                    background-image: none;
                    background-color: $color-light-grey;
                    border-radius: 50%;
                    color: $white-color;
                    padding: 0.0625em 0.1875em;
                }
            }

            .accordion-body {
                padding: 0.9375em;
                height: auto;
                max-height: 25em;
                overflow-y: auto;
            }
        }
    }
}

.modal-content {
    background-color: $light-grey-bg;
    border-radius: 0.25rem;
    border: none;


    .modal-header {
        border-bottom: none;
        padding: 2.125rem 1.25rem;
        position: relative;

        .modal-title {
            line-height: 0;
            text-align: center;
        }
    }

    .modal-body {
        background-color: $white-bg;
        padding: 2.25rem;
        margin: 2.25rem;
        max-height: calc(60vh);
        overflow-y: auto;
        overflow-x: hidden;

        @include sm {
            padding: 0.8125em;
            margin: 0.8125em;
        }

        .day-line {
            h3 {
                color: #554E4E;
                font-size: 1.125rem;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.125rem;

                @include lg {
                    font-size: 1em;
                }
            }
        }

        .time-line {
            i {
                color: $color-light-grey;
            }

            span {
                color: $color-grey;
                font-size: 1rem;

                @include lg {
                    font-size: 0.875em;
                }
            }
        }

        .class-heading {
            h4 {
                background-color: $light-grey-bg;
                padding: 1.125rem 1.25rem;
                color: $dark-black;
                font-size: 1.125rem;
                font-weight: 600;
                letter-spacing: 2px;

                @include lg {
                    font-size: 1em;
                }
            }

            span {
                font-size: 14px;
            }
        }

        .class-details {

            p,
            a {
                color: $black-color;
                font-size: 1.125rem;
                font-weight: 400;
                word-wrap: normal;

                @include lg {
                    font-size: 0.875em;
                }
            }
        }

        .passes-box {
            .passes-details {
                p {
                    text-align: left;
                    text-overflow: ellipsis;
                    text-wrap: wrap;
                    overflow: hidden;

                    @include sm {
                        font-size: 0.875em;
                        margin-right: 5px;
                    }
                }

                .passes-amount {
                    span {
                        @include sm {
                            font-size: 0.875em;
                        }
                    }
                }
            }

            .btn-group {
                .btn-secondary {
                    background-color: $light-grey-bg;
                    color: $color-grey;
                    border-radius: 0;
                    border: none;
                    padding: 0.9375rem;
                    border: 0.0625em solid transparent;

                    &.selected-box {
                        border: 0.0625em solid #CC257A;
                        background-color: #FFE2F1;

                        .details-attend {

                            .pass-type,
                            .expiry-date {
                                label {
                                    color: #344054;
                                    font-size: 14px;
                                    width: 20%;

                                    @media (max-width: 950px) {
                                        width: auto;
                                    }
                                }

                                i {
                                    color: #98A2B3;
                                    margin-right: 10px;
                                    margin-left: 5px;
                                    font-size: 14px;
                                }

                                span {
                                    color: #000000;
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    &.active {
                        border: 0.0625em solid $color-pink;
                        background-color: #FFE2F1;
                    }

                    input[type='radio'] {
                        background-color: $color-light-grey;
                        width: 0.9375em;
                        height: 0.9375em;

                        &:checked {
                            &:after {
                                content: "\f00c";
                                font-family: FontAwesome;
                                display: block;
                                visibility: visible;
                                background-color: $color-pink;
                                color: $white-color;
                                border-radius: 50%;
                                height: 15px;
                                width: 15px;
                                font-size: 0.625em;
                                right: 0em;
                                padding: 0px 0px 3px 3px;
                                border: none;
                            }
                        }
                    }

                    &.active-event {
                        border: 0.0625em solid #64D576;
                        background-color: #D8FFDE;

                        .details-attend {
                            display: flex;


                            .lable-title {
                                display: flex;
                                width: 6.25em;

                                h4 {
                                    color: #344054;
                                    font-size: 0.875em;
                                    font-weight: 500;

                                    @include sm {
                                        font-size: 0.875em;
                                    }
                                }
                            }

                            .lable-date {
                                display: flex;

                                p {
                                    color: $dark-black;
                                    font-weight: 400;
                                    font-size: 1em;
                                    margin-right: 0.5em;
                                    position: relative;
                                    bottom: 0.125em;

                                    @include lg {
                                        font-size: 0.875em;
                                    }
                                }

                                i {
                                    color: #98A2B3;
                                    margin-right: 0.625em;
                                    margin-left: 0.3125em;
                                }
                            }

                            .lable-day {
                                display: flex;

                                p {
                                    color: $dark-black;
                                    font-weight: 400;
                                    font-size: 1em;
                                    margin-right: 0.5em;
                                    position: relative;
                                    bottom: 0.125em;

                                    @include lg {
                                        font-size: 0.875em;
                                    }
                                }

                                i {
                                    color: $color-pink;
                                    margin-right: 7px;
                                    margin-left: 6px;
                                    font-size: 0.375em;
                                    margin-top: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-header {
    .btn-close {
        color: red;
        border-radius: 50%;
        border: 0.125rem solid red;
        background-image: none;
        position: relative;
        opacity: 1;
        padding: 4px;
        bottom: 0px;

        &:focus {
            box-shadow: none;
        }

        @include sm {
            padding: 2px;
            position: relative;
            bottom: 15px;
        }

        i {
            position: relative;
            bottom: 0.1875rem;
        }
    }
}

.btn-primary {
    background-color: $color-pink;
    border-radius: 0;
    border: 0.0625em solid $color-pink;
    font-size: 0.875em;
    color: $white-color;

    @include sm {
        font-size: 1.125em;
    }

    @include lg {
        font-size: 1em;
    }


    &:hover,
    &:active,
    &:focus-visible {
        color: $color-pink !important;
        background-color: $white-bg !important;
        transition: 0.5s;
        border-color: $color-pink !important;
    }
}

.payment-section {
    position: relative;
    height: 100%;
    max-height: 37.5em;
    overflow-y: auto;


    @include lg {
        display: block;

        .col-md-8 {
            width: 100%;
        }
    }

    img {
        width: 31.25em;
        z-index: 99;
        position: relative;
        margin-bottom: 1.875em;
        max-height: 400px;

        @include lg {
            display: none;
        }
    }

    .payment-box {
        background-color: $color-pink;
        color: $white-color;
        padding: 0.75em 1em 0em;
        margin: 0;
        position: absolute;
        top: 3.75em;
        z-index: 0;
        left: 1.5%;
        width: 98.4%;

        @include lg {
            position: relative;
            top: 0;
            padding-bottom: 0.625em;
            margin-bottom: 0.625em;
        }

        .payment-content {
            text-align: right;

            @media (max-width: 450px) {
                text-align: left;
            }

            h2 {
                font-size: 1.5em;
                letter-spacing: 0.125em;

                @include sm {
                    font-size: 1.25em;
                }
            }

            p {
                @include sm {
                    font-size: 1em;
                }
            }

            .class-heading {
                border-bottom: 0.0625em dashed $dark-black;
                padding-bottom: 0.9375em;

                h3 {
                    max-width: 300px;
                    margin-left: auto;
                    font-size: 1.125em;
                    font-weight: 600;
                    letter-spacing: 0.125em;

                    @media (max-width: 450px) {
                        margin-left: 0;
                    }

                    @include sm {
                        font-size: 1em;
                    }
                }

                span {
                    @include sm {
                        font-size: 0.875em;
                    }
                }
            }

            .class-payment {
                ul {
                    list-style-type: none;

                    li {
                        display: flex;
                        margin-left: auto;
                        justify-content: end;
                        font-size: 1em;

                        p {
                            margin-right: 0.625em;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}

.remove-content {
    p {
        color: $red-color;
        font-size: 1em;
        font-weight: 600;
    }

    h3 {
        font-size: 1.5em;
        color: $color-grey;
        font-weight: 600;
        letter-spacing: 0.0625em;
    }
}

.btn-light {
    background-color: $white-bg;
    color: $color-pink;
    border-radius: 0;
    border: 0.0625em solid $color-pink;

    &:hover {
        background-color: $color-pink;
        color: $white-bg;
        transition: 0.5s;
        border-color: $color-pink;
    }
}

.confirm-content {
    text-align: center;

    h2 {
        color: #73D063;
        font-size: 1.5em;
        font-weight: 500;
        letter-spacing: 0.0625em;
    }

    p {
        color: $dark-black;
        font-size: 1.1875em;
        font-weight: 600;
        letter-spacing: 0.125em;
    }

    span {
        font-size: 1.1875em;
        color: $color-grey;
    }
}

.promo-code {
    position: absolute;
    right: 0.625em;
    bottom: 0em;
    width: 50%;

    @include lg {
        position: relative;
        right: 0;
        width: 100%;
    }

    input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-color: $color-pink;
        color: $dark-black;
        font-weight: 600;
        padding-bottom: 0.1875em;
        padding-top: 0.1875em;

        @include sm {
            font-size: 14px;
        }
    }
}

.payment-form {
    background-color: $light-grey-bg;
    padding: 1.25em;

    @include sm {
        .mt-3 {
            margin-top: 0em !important;
        }
    }

    h3 {
        color: $black-color;
        font-weight: 600;
        font-size: 1.1875em;
        letter-spacing: 0.125em;
        margin-top: 10px;
        margin-left: 10px;
    }

    img {
        width: 4%;

        @include lg {
            display: block;
            width: 6%;
        }
    }

    .form-contact {
        @include sm {
            margin-bottom: 0.625em;
        }

        .form-control,
        .form-select {
            border-radius: 0.125em;
            padding: 0.375rem 0.75rem;
            color: $color-light-grey;

            @include sm {
                padding: 0.5em 0.625em;
                font-size: 0.875em;
            }

            @include lg {
                padding: 0.5em 0.625em;
                font-size: 1em;
            }

            &:focus {
                box-shadow: none;
                border-color: $color-pink;
            }

            &::placeholder {
                border-color: $color-pink;
            }
        }

    }

    label {
        color: $black-color;
        font-size: 0.875em;
        font-weight: 500;

        @include sm {
            margin-bottom: 0em;
        }

        i {
            color: $color-pink;
        }
    }
}

.form-check-input {
    &:checked {
        background-color: $color-pink;
        border-color: $color-pink;
    }

    &:focus {
        box-shadow: none;
        border-color: $color-pink;
    }
}

.remove-content {
    p {
        color: $red-color;
        font-size: 1em;
        font-weight: 600;
    }

    h3 {
        font-size: 1.5em;
        color: $color-grey;
        font-weight: 600;
        letter-spacing: 0.0625em;
    }
}

.btn-light {
    background-color: $white-bg;
    color: $color-pink;
    border-radius: 0;
    border: 0.0625em solid $color-pink;

    &:hover {
        background-color: $color-pink;
        color: $white-bg;
        transition: 0.5s;
        border-color: $color-pink;
    }
}

.confirm-content {
    text-align: center;

    h2 {
        color: #73D063;
        font-size: 1.5em;
        font-weight: 500;
        letter-spacing: 0.0625em;

        @include lg {
            font-size: 1.25em;
        }
    }

    p {
        color: $dark-black;
        font-size: 1.1875em;
        font-weight: 600;
        letter-spacing: 0.125em;

        @include lg {
            font-size: 1.125em;
        }
    }

    span {
        font-size: 1.1875em;
        color: $color-grey;

        @include lg {
            font-size: 1em;
        }
    }
}

.border-bottom-dashed {
    border-bottom: 0.0625em dashed $dark-black;
}

.w-35 {
    width: 35%;

    @include sm {
        width: 50%;
    }
}

.padding-around {
    padding: $section-padding;

    @include sm {
        padding: $section-padding-mobile;
    }
}


/*login css*/

.login-section {
    position: relative;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;

    @include lg {
        height: auto;
    }

    &::before {
        position: absolute;
        content: '';
        background: url('../src/assets/img/banner-pattern-2.svg');
        background-repeat: no-repeat;
        z-index: -1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden !important;
        width: 22.375em;
        height: 23.375em;

        @media (max-width: 990px) {
            height: auto;
            width: auto;
        }
    }

    &::after {
        position: absolute;
        content: '';
        background: url('../src/assets/img/banner-pattern-1.svg');
        background-repeat: no-repeat;
        height: 17.5625em;
        z-index: -1;
        bottom: -8px;
        right: 0.3125em;
        width: 21.5em;
        background-size: cover;

        @media (max-width: 1400px) {
            bottom: -150px;
        }

        @media (max-width: 950px) {
            bottom: 0px;
        }
    }

    .card {
        z-index: 8;
        position: relative;

        @media (max-width: 1400px) {
            margin-top: 70px;
        }

        @include lg {
            margin: 3em 0px 11em;
        }

        @media (max-width: 990px) {
            margin: 10em 0px 11em;
        }

        .card-body {
            padding: 1.5em 3em;

            h2 {
                font-size: 1.5em;
            }
        }
    }
}

.form-label {
    color: $black-color;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3em;

    @include lg {
        margin-bottom: 0.3125em;
    }

    span {
        color: $red-color;
    }
}

p {
    color: $color-grey;
}

.login-btn {
    color: $white-color;
    font-size: 1em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2em;
    background-color: $color-pink;
    border-color: $color-pink;
    width: 100%;
    padding: 0.625em;
    border-radius: 0.125em;
    box-shadow: 0em 0.0625em 0.125em 0em rgba(16, 24, 40, 0.05);
    cursor: pointer;
    border: 1px solid $color-pink;
}

.login-btn:hover {
    color: $color-pink;
    border-color: $color-pink;
    background-color: $white-color;
}

.login-footer {
    position: relative;
    bottom: 0;
    padding: 3.4375em 0em;
    background-color: #000;
    min-height: 200px;

    @include lg {
        min-height: auto;
        position: relative;
        bottom: auto;
    }

    @media (max-width: 1400px) {
        position: relative;
        bottom: auto;
        min-height: auto;
        margin-top: 150px;
    }

    @media (max-width: 950px) {
        margin-top: 0;
    }

    .footer-order {
        @include lg {
            order: 0
        }

        .info {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                height: 11.5625em;
                width: 5em;
                background-image: url("assets/img/footer-icon.svg");
                background-repeat: no-repeat;
                display: table;
                z-index: -1;
                left: 0px;
                bottom: 5.9375em;
                right: 0;
                margin: 0 auto;

                @media (max-width: 991px) {
                    width: 6em;
                    bottom: 7.9375em;
                }
            }
        }
    }

    .footer-sub {
        @include lg {
            order: 1
        }

        .footer-media {
            @include lg {
                text-align: center;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column-reverse;
                gap: 0.9375em;
            }

            .footer-top {
                font-size: 1em;
                font-weight: 400;
                color: $white-color;
            }

            .fb-icon {
                height: 2.25em;
                width: 2.25em;
                background: #FFF;
                border-radius: 0.75em;
                text-align: center;
                line-height: 2.25em;
                margin: 0.3125em;
            }

            .insta-icon {
                height: 2.25em;
                width: 2.25em;
                background: #FFF;
                border-radius: 0.75em;
                text-align: center;
                line-height: 2.25em;
                margin: 0.3125em;
            }

            .tw-icon {
                height: 2.25em;
                width: 2.25em;
                background: #FFF;
                border-radius: 0.75em;
                text-align: center;
                line-height: 2.25em;
                margin: 0.3125em;
            }
        }
    }

    .footer-text {
        margin: 1.25em 0em;

        p {
            color: #696969;

        }

        .footer-last {
            border-radius: 0.5em;
            border: 0.0625em solid #4A4A4A;
            align-items: center;
            justify-content: end;
            text-align: center;
            padding: 0.125em;

            i {
                color: #fff;
                border-radius: 0.5em;
                border: 0.0625em solid #4A4A4A;
                width: 2.5em;
                height: 2.5em;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 2.875em;
                text-align: center;
                background: #4A4A4A;
                padding: 1em;
            }
        }
    }
}

.footer .carousel-control-prev {
    z-index: 9;
    display: block;
    left: -100px;
    top: 0;
    background: $color-pink;
    opacity: 1;
    border-radius: 10px;
    position: absolute;
    height: 36px;
    width: 36px;
    margin-top: 80px;

    @media (max-width: 767px) {
        left: -100px;
        height: 30px;
        width: 30px;
    }

    @media (max-width: 550px) {
        left: -50px;
    }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-size: 100% 50%;
    width: 30px;
    position: relative;

    @media (max-width: 767px) {
        background-size: 100% 40%;
        width: 30px;
    }
}

.client-say {
    margin: 0 auto;
    width: 50%;
    word-break: break-word;
}

.footer .carousel-control-next {
    z-index: 9;
    display: block;
    right: -100px;
    top: 0;
    background: $color-pink;
    opacity: 1;
    border-radius: 10px;
    justify-content: start;
    position: absolute;
    height: 36px;
    width: 36px;
    margin-top: 80px;

    @media (max-width: 767px) {
        right: -100px;
        height: 30px;
        width: 30px;
    }

    @media (max-width: 550px) {
        right: -50px;
    }
}

.pointer {
    cursor: pointer;
}

/*Event css Start*/
#events-card {
    .card-body {
        min-height: 570px;
    }

    @include sm {
        .card-body {
            min-height: auto;
        }
    }
}

.event-section {
    background-color: $light-grey-bg;

    .session-box {
        .session-textbox {
            .session-search {
                i {
                    position: absolute;
                    left: 20px;
                    padding-left: 0.625em;
                    top: 0.875em;
                    color: #98A2B3;
                }
            }
        }
    }
}

.card-img-top {
    max-height: 450px;
    border-radius: 0px;
    height: 100vh;
    object-fit: cover;
    width: 100%;
    border: 1px solid #f1f1f1;
}

.search-filter {
    display: flex;
    justify-content: end;
    position: absolute;
    top: 12px;
    right: 26px;

    i {
        color: $color-light-grey;
        font-size: 18px;
        padding: 0px 20px;
        border-left: 1px solid $color-light-grey;
    }
}

.card-header {
    border-bottom: none;
    text-align: center;
    background: $white-bg;
    display: flex;
    justify-content: space-between;

    .event-box {
        text-align: left;
        margin-top: 1.5625rem;
        font-size: 1.25rem;
        color: $black-color;
        font-weight: 600;

        @include sm {
            font-size: 1.25em;
            margin-right: 0.625em;
        }
    }
}

.ribbon {
    float: right;
    width: 4.75em;
    height: 3.125rem;
    background-color: $color-pink;
    position: relative;
    color: $white-bg;
    text-align: center;
    padding-top: 0.3125rem;
    box-shadow: 0px 4px 4px 0px #73033C inset;
    top: -9px;
    right: 11px;

    &::before {
        content: '';
        position: absolute;
        z-index: 2;
        left: 0;
        bottom: -2.1rem;
        border-left: 2.375em solid $color-pink;
        border-right: 2.375em solid $color-pink;
        border-bottom: 2.1875rem solid transparent;
    }

    @include lg {
        width: 76px;
    }

    @include md {
        width: 89px;
    }

    @include sm {
        width: 76px;
    }

    &.booked {
        background-color: #99CB27;
        box-shadow: 0px 4px 4px 0px #5F8509 inset;

        @include lg {
            width: 76px;
        }

        @include md {
            width: 76px;
        }



        &::before {
            border-left: 2.375em solid #99CB27;
            border-right: 2.375em solid #99CB27;

        }

        span {
            position: relative;
            top: 10px;
        }
    }


    &.sold-out {
        background-color: #FF4A46;
        box-shadow: 0px 4px 4px 0px #C6090D inset;

        &::before {
            border-left: 2.375em solid #FF4A46;
            border-right: 2.375em solid #FF4A46;
        }

        @include lg {
            width: 76px;
        }

    }
}


@media (max-width: 450px) {
    .modal-header .btn-close {
        padding: 2px;
        position: relative;
        bottom: -3px;
    }
}

.card-body {
    border-style: none !important;
    position: relative;
    cursor: pointer !important;

    .card-title {
        span {
            margin-left: 8px;
        }

        @media (max-width: 767px) {
            .d-flex {
                display: block !important;
            }
        }


        .day {
            i {
                color: $color-pink;
                font-size: 7px;
                position: relative;
                top: -3px;
                margin: 5px;

                @include md {
                    top: -3px;
                }
            }
        }

        .clock-img {
            font-size: 16px;
            margin-left: 4px;
            color: $color-light-grey;
            margin-right: 3px;
        }
    }

    .btn-dark {
        background: $color-pink;
        color: $white-bg;
        border: none;
        text-align: center;
        line-height: 3.125rem;
        font-size: 2rem;
        margin: auto;
        position: absolute;
        border-radius: 0px !important;
        box-shadow: 0px 5px 20px 0px #00000040 inset !important;
        padding: 15px 20px;
        top: -47px;
        right: 11px;

        &:hover {
            background: $color-pink;
            color: $white-bg;
        }

        &::before {
            border-bottom: 46px solid #97044F;
            border-left: 44px solid transparent;
            border-right: 0px solid transparent;
            width: 0;
            height: 0;
            content: "";
            display: block;
            position: absolute;
            left: -44px;
            top: 0;
        }

        @include lg {
            line-height: 44px;
            padding: 8px 20px;
            font-size: 22px;
            top: -46px;
        }

        @include sm {
            line-height: 44px;
            padding: 8px 20px;
            font-size: 22px;
            top: -46px;
        }

    }

    .btn-dark .date {
        color: $dark-black;
        font-size: 0.9375rem;
        font-weight: 600;
        padding-left: 0.625rem;
    }
}

.text-heading {
    font-size: 0.9375rem;
    font-weight: 500;
    color: $dark-black;
    padding-top: 6px;
    text-align: left;
    display: flex;
    align-items: flex-start;
    margin-left: 4px;

    @media (max-width: 990px) {
        text-align: left;
        margin-right: 0;
        margin-left: 6px;
        margin-top: 5px;
    }

    @media (max-width: 550px) {
        text-align: left;
        margin-right: 0;
        margin-left: 6px;
        margin-top: 5px;
    }


    @include lg {
        display: inline-block;
    }

}

.record-toggle {
    .btn-outline-primary {
        background-color: $light-grey-bg;
        border-radius: 0;
        border: none;
        color: $color-light-grey;

        &:active {
            background-color: $light-grey-bg;
            color: $color-light-grey;
        }
    }
}

.paggination-page {
    display: flex;

    .page-item {
        margin: 5px;

        .page-link {
            border-radius: 5px;
            border-color: $color-light-grey;
            color: #505470;
        }
    }

    @include sm {
        margin-top: 30px;
    }
}

.page-link.active,
.active>.page-link {
    background-color: $color-pink !important;
    color: $white-color !important;
}

.page-text {
    color: $color-light-grey;
    font-size: 0.8125rem;
    margin-left: 0.9375rem;
}

.page-link {
    position: relative;
    padding: 0.625rem;
    display: block;
    font-size: 0.8125rem;
    color: #505470;
    height: 1.875rem;
    line-height: 0.625rem;
    text-align: center;
    text-decoration: none;
    background-color: $white-bg;
    border: 1px solid $color-light-grey;
    border-radius: 2px solid $color-light-grey;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-link:hover {
    text-decoration: none;
    background-color: $white-bg;
    border: 0.0625rem solid $color-light-grey;
    border-radius: 0.125rem solid $color-light-grey;
}

.page-link-active {
    background: $color-pink !important;
    color: $white-bg;
    position: relative;
    padding: 0.625rem;
    display: block;
    font-size: 0.8125rem;
    height: 30px;
    line-height: 0.625rem;
    text-align: center;
    text-decoration: none;
}

/*Event css End*/

/*About us Start*/
.about-content {
    padding: $section-padding;
    padding-bottom: 5em;

    .about-text {
        .headingtext {
            @include heading;
            text-align: left;
        }
    }

    .image-box {
        background: $white-bg;
        box-shadow: 0px 0px 14px 0px rgba(194, 209, 224, 0.50);
        padding: 1em;

        .img-fluid {
            height: 450px;
            object-fit: cover;
            width: 100%;

            @media (max-width: 800px) {
                height: auto;
                object-fit: cover;
                width: 100%;
            }
        }

        @include lg {
            margin-bottom: 2em;
        }
    }


    p {
        text-align: left;
        color: $color-grey;
        font-size: 0.9em;
        letter-spacing: 1.52px;
    }

    &.about-bg-grey {
        background-color: $light-grey-bg;

        .row {
            @include lg {
                flex-direction: column-reverse;
            }
        }
    }
}

/*About us End*/

/*Contact us Start*/
.contact-content {
    padding: $section-padding;
    background-image: url("assets/img/contact-bg.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;

    .contact-box {
        display: flex;
        align-items: start;
        justify-content: start;
        margin-top: 3em;

        .grey-circle {
            background-color: $light-grey-bg;
            min-width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
        }

        .grey-content {
            h6 {
                color: $black-color;
                font-size: 1.2em;
                letter-spacing: 0.96px;
                text-transform: uppercase;
            }

            p {
                color: $black-color;
                font-size: 1.1em;
            }
        }
    }

    .grey-box {
        background-color: $light-grey-bg;
        padding: 3em;

        .headingtext {
            margin: 0 auto;
            @include heading;
            display: table;
        }

    }
}

/*Contact us End*/

.my-profile-content {
    padding: $section-padding;
}

/*Start Event details page*/
.event-heading {
    a {
        color: $color-grey;
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        min-width: 100px;
    }

    p {
        color: $color-pink;
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.event-details-box {
    background-color: $light-grey-bg;
    padding: 50px;

    @include sm {
        padding: 10px 15px;
    }

    .mySlides {
        display: none;
        background-color: $white-bg;
        padding: 10px;
    }

    .preview-image {
        height: 100vh;
        max-height: 550px;
        object-fit: cover;

        @include sm {
            max-height: 350px;
        }
    }


    .column {
        float: left;
        width: 33.3%;
        margin-top: 20px;

        img {
            background-color: $white-bg;
            padding: 5px;
            height: 150px;
            object-fit: cover;
            margin: 30px 0px;
        }

    }

    .demo {
        opacity: 0.6;

        &.active {
            opacity: 1;
        }

        &:hover {
            opacity: 1;
        }
    }

    .event-section-heading {
        border-bottom: 1px solid $color-light-grey;
        padding-bottom: 20px;

        h2 {
            color: $black-color;
            font-size: 28px;
            font-weight: 600;
            letter-spacing: 1px;

            @include lg {
                font-size: 22px;
            }
        }

        span {
            color: $color-pink;
            font-size: 28px;
            font-weight: 600;
            letter-spacing: 1px;

            @include lg {
                font-size: 22px;
            }
        }


    }

    .description {
        height: auto;
        max-height: 436px;
        overflow-y: scroll;
        margin-bottom: 20px;

        @media (max-width: 550px) {
            margin-bottom: 0;
        }
    }

    .event-section-body {
        margin-top: 15px;

        a{
            color: $color-pink;
        }

        p {
            padding: 10px;
            color: $black-color;
            font-size: 15px;
            font-weight: 400;
            word-break: break-word;

            @include lg {
                font-size: 14px;
                padding: 0;
            }
        }

        .amount {
            color: $color-pink;
            font-weight: 600;
            font-size: 15px;
        }

        .stepper__input {
            width: 150px;
            max-width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-color: $white-bg;
            padding: 15px;

            @include lg {
                width: 100px;
                padding: 6px;
            }
        }

        .stepper__input .stepper-input-field {
            color: $color-pink;
            font-size: 18px;
            font-weight: bold;
            padding: 0;
            background: transparent;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            height: 25px;
            line-height: 25px;
            border: none;
            text-align: center;
            margin: 0 7px;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;

            @include lg {
                height: 25px;
                font-size: 14px;
            }
        }

        .stepper__input button {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            background-color: #F1F2F3;
            border: 1px solid #CBCFD4;
            min-width: 25px;
            height: 30px;
            line-height: 25px;
            color: $color-pink;
            user-select: none;

            @include md {
                font-size: 14px;
            }
        }
    }

}

.map-section {
    h3 {
        color: $black-color;
        font-size: 20px;
        font-weight: 600;
    }
}

/*start event question CSS*/
.event-que {
    form {
        .form-contact {

            .form-select,
            .form-control {
                border-radius: 0;
                padding: 13px 19px;
                width: 97.5%;
                margin-left: 3px;

                &:focus {
                    box-shadow: none;
                    border-color: $color-pink;
                }
            }

            label {
                color: $black-color;
                font-size: 16px;
            }

            textarea {
                border-radius: 0;
                padding: 13px 19px;
                width: 97.5%;
                margin-left: 30px;

                &:focus {
                    box-shadow: none;
                    border-color: $color-pink;
                }
            }

            .form-check {
                .form-check-input {
                    &:focus {
                        box-shadow: none;
                    }

                    &:checked {
                        &:after {
                            content: "\f111";
                            font-family: FontAwesome;
                            display: block;
                            visibility: visible;
                            background-color: $white-bg;
                            color: $color-pink;
                            border-radius: 50%;
                            height: 16px;
                            width: 16px;
                            font-size: 0.625em;
                            right: 0em;
                            padding: 0px 4px 4px 1.5px;
                            left: -0.6px;
                            position: relative;
                            top: -1.6px;
                            border: 1px solid $color-pink;
                        }
                    }
                }
            }
        }
    }
}

/*Start Shopping-cart CSS*/

.cart-box {

    background-color: $light-grey-bg;
    padding: 20px 30px;

    table {
        thead {
            tr {
                th {
                    color: $color-pink;
                    font-size: 15px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.checkout-table {
    background-color: $light-grey-bg;
    padding: 0;

    .cart-box {
        height: 734px;
        overflow-y: auto;
        overflow-x: hidden;

        @media screen and (max-width:991px) {
            height: auto;
        }

        table {
            thead {
                tr {
                    th {
                        color: $color-pink;
                        font-size: 15px;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .btn-group {
        padding: 20px 30px;
    }
}

/*Event shppoing cart*/
.shopping-cart {
    table {

        th,
        td {
            background-color: #f5f5f5 !important;
        }

        thead {
            border-bottom: 1px solid #CBCFD4;
            margin-bottom: 20px;

            th {
                padding-bottom: 10px;
            }

        }

        tbody {
            tr {
                td {
                    padding: 12px 0;

                    .table-details {
                        display: flex;

                        .img-resize {
                            width: 125px !important;
                            height: 110px !important;
                            object-fit: none !important;
                        }

                        @media (max-width: 991px) {
                            align-items: center;
                            gap: 15px;
                            width: max-content;
                        }

                        .card-body {
                            margin-left: 20px;

                            @media (max-width: 767px) {
                                margin-left: 0;
                            }

                            h3 {
                                color: #554E4E;
                                font-size: 22px;
                                font-weight: 600;
                                margin-bottom: 0px;


                                @media (max-width: 1040px) {
                                    margin-top: 30px;
                                }
                            }

                            .date-time-setion {
                                display: flex;
                                padding: 0 8px 0 0;

                                .card-title {
                                    display: flex;
                                    align-items: flex-start;
                                }

                                @media (max-width:1040px) {
                                    display: block;

                                    label {
                                        display: none;
                                    }
                                }
                            }

                            .event-lable {
                                background: #fff;
                                padding: 6px 10px;
                                width: fit-content;
                                color: #554E4E;

                                .eye-icon {
                                    color: #6BD67C;
                                    margin: 0 10px;
                                }

                                .edit-icon {
                                    color: #6792FF;
                                    background-color: transparent;
                                    border: none;
                                    position: relative;
                                    top: 0;
                                    right: 0;
                                    height: auto;
                                    width: auto;
                                }
                            }
                        }

                        .img-table {
                            width: 15%;
                            background: #fff;
                            padding: 7px;
                            border-radius: 6px;
                            object-fit: cover !important;
                            height: 100%;
                            position: relative;
                            top: 5px;

                            @media (max-width:1040px) {
                                width: 25%;
                            }

                        }
                    }

                    h5 {
                        color: #554E4E;
                        font-size: 16px;
                        width: 100px;
                        margin-top: 50px;
                    }

                    .amount {
                        color: $color-pink;
                        font-weight: 600;
                        font-size: 15px;
                    }

                    .stepper__input {
                        width: 120px;
                        margin: 0 30px;
                        max-width: 100%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        background-color: $white-bg;
                        padding: 8px;
                        position: relative;
                        right: 15px;
                        margin-top: 40px;

                        @include lg {
                            width: 100px;
                            padding: 6px;
                        }
                    }

                    .stepper__input .stepper-input-field {
                        color: $color-pink;
                        font-size: 18px;
                        font-weight: bold;
                        padding: 0;
                        background: transparent;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        height: 25px;
                        line-height: 25px;
                        border: none;
                        text-align: center;
                        margin: 0 7px;
                        -webkit-box-flex: 1;
                        -ms-flex-positive: 1;
                        flex-grow: 1;

                        @include lg {
                            height: 25px;
                            font-size: 14px;
                        }
                    }

                    .stepper__input button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        cursor: pointer;
                        background-color: #F1F2F3;
                        border: 1px solid #CBCFD4;
                        min-width: 25px;
                        height: 30px;
                        line-height: 25px;
                        color: $color-pink;
                        user-select: none;

                        @include md {
                            font-size: 14px;
                        }
                    }

                    .btn-close {
                        padding: 5px;
                        position: relative;
                        top: 50px;
                        cursor: pointer;

                        @media (max-width: 1040px) {
                            padding: 6px;
                            margin: 10px;
                        }

                        i {
                            display: none;
                            position: relative;
                            top: -3px;
                            right: -2px;
                        }
                    }
                }
            }
        }
    }

    .coupon-section {
        .promo-code {
            position: relative;
            right: 0.625em;
            bottom: 0em;
            width: 60%;

            @include lg {
                position: relative;
                right: 0;
                width: 100%;
            }

            input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-color: $color-pink;
                color: $dark-black;
                font-weight: 600;
                padding-bottom: 0.1875em;
                padding-top: 0.1875em;
                line-height: 2.5;

                @include sm {
                    font-size: 14px;
                }
            }

            .btn-primary {
                height: 50px;
                width: 100px;
            }
        }

        .total-section {
            ul {
                text-align: right;

                li {
                    .cart-section {
                        display: flex;
                        justify-content: end;

                        p {
                            padding-right: 30px;
                            text-wrap: nowrap;
                        }

                        span {
                            padding-left: 30px;
                            text-wrap: nowrap;
                            min-width: 110px;
                        }

                        label {
                            color: $color-pink;
                        }
                    }

                    .total-cart {
                        border-top: 2px dashed #ccc;
                        color: $color-pink;
                        font-weight: 700;
                        padding-top: 15px;

                        .cart-section {
                            p {
                                color: $color-pink;
                                padding-left: 30px;
                                text-wrap: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }
}

.checkout-content {
    padding: 20px 0;

    h3 {
        color: $color-pink;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-size: 22px;
    }

    p {
        color: #554E4E;
        font-size: 14px;
    }

    .form-contact {
        label {
            color: #344054;
            font-size: 14px;
        }

        .form-control {
            border-radius: 0;
            font-size: 14px;
            height: 40px;
        }
    }

    .active-lock {
        margin: 10px 0;

        i {
            color: #98A2B3;
            font-size: 14px;
        }

        span {
            color: #554E4E;
            font-size: 14px;
            margin-left: 10px;
        }
    }
}

.remove-lable {
    background-color: #FFDECE;
    padding: 15px;
    border: 1px solid #FF8146;
    margin-bottom: 15px;
    margin-left: auto;
    width: fit-content;

    label {
        color: #EC5710;
        margin: 0 10px;
    }

    span {
        color: #554E4E;
        font-size: 14px;
    }

    a {
        background-color: #fff;
        padding: 5px 11px;
        margin-left: 70px;
        color: $color-pink;
        border: 1px solid transparent;

        @include sm {
            margin-left: 20px;
        }

        &:hover {
            border: 1px solid $color-pink;
            transition: 0.5s;
        }
    }
}

.remove-cart {
    .remove-content {
        p {
            color: #F5141A;
            font-size: 16px;
            font-weight: 600;
        }

        h2 {
            color: #554E4E;
            font-size: 24px;
            font-weight: 600;
        }

        .img-section {
            img {
                display: block;
                margin: 0 auto;
                width: 50%;
                margin-bottom: 30px;
            }
        }
    }

    .btn-group {
        .btn-secondary {
            background: #fff;
            border: 1px solid $color-pink;
            color: $color-pink;
            border-radius: 0;

            &:hover {
                background-color: $color-pink;
                color: $white-color;
                border: 1px solid $color-pink;
                transition: 0.5s;
            }
        }
    }

}

.checkout-table {
    .shopping-cart {
        table {

            th,
            td {
                background-color: #f5f5f5 !important;
            }

            thead {
                border-bottom: 1px solid #CBCFD4;
                margin-bottom: 20px;

                th {
                    padding-bottom: 10px;
                    font-size: 15px;
                }

            }

            tbody {
                tr {
                    td {
                        padding: 12px 0;

                        .table-details {
                            display: flex;

                            @media (max-width: 991px) {
                                align-items: center;
                                gap: 15px;
                                width: max-content;
                            }

                            .card-body {
                                margin-left: 20px;

                                @media (max-width: 767px) {
                                    margin-left: 0;
                                }

                                h3 {
                                    color: #554E4E;
                                    font-size: 18px;
                                    font-weight: 600;
                                    margin-bottom: 0px;


                                    @media (max-width: 1040px) {
                                        margin-top: 30px;
                                    }
                                }

                                .date-time-setion {
                                    display: block;
                                    padding: 0 8px 0 0;

                                    @media (max-width:1040px) {
                                        display: block;

                                        label {
                                            display: none;
                                        }
                                    }

                                    .date {
                                        font-size: 14px;
                                    }
                                }

                                .text-heading {
                                    font-size: 14px;
                                }

                                .event-lable {
                                    background: #fff;
                                    padding: 6px 10px;
                                    width: fit-content;
                                    color: #554E4E;

                                    .eye-icon {
                                        color: #6BD67C;
                                        margin: 0 10px;
                                    }

                                    .edit-icon {
                                        color: #6792FF;
                                    }
                                }
                            }

                            .img-table {
                                width: 25%;
                                object-fit: cover;
                                height: 100%;
                                position: relative;
                                top: 5px;
                                background: transparent;
                                border-radius: 0;
                                padding: 0;

                                @media (max-width:1040px) {
                                    width: 25%;
                                }

                            }
                        }

                        h5 {
                            color: #554E4E;
                            font-size: 16px;
                            width: 100px;
                            margin-top: 50px;
                            // margin-right: 8px;
                        }

                        .amount {
                            color: $color-pink;
                            font-weight: 600;
                            font-size: 15px;
                        }
                    }
                }
            }
        }

        .total-section {
            ul {
                text-align: right;
                padding-left: 10px;

                li {
                    .cart-section {
                        display: flex;
                        justify-content: end;


                        span {
                            padding-left: 30px;
                            min-width: 110px;
                            text-wrap: nowrap;
                        }

                        label {
                            color: $color-pink;
                        }
                    }

                    .total-cart {
                        border-top: 2px dashed #ccc;
                        color: $color-pink;
                        font-weight: 700;
                        padding-top: 15px;

                        .cart-section {
                            p {
                                color: $color-pink;
                                padding-left: 30px;
                                text-wrap: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }
}

.headings {
    padding-bottom: 30px;

    a {
        color: #554E4E;
        font-weight: 600;
        font-size: 18px;
    }

    p {
        color: $color-pink;
        font-size: 18px;
        font-weight: 600;

    }
}

.success-payment {
    .success-content {
        img {
            display: block;
            margin: 0 auto;
            width: 70%;
            margin-bottom: 30px;
        }

        h2 {
            color: #73D063;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
        }

        p {
            color: #554E4E;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
        }
    }
}


/****Start Profile page********/

.profile-tab {
    display: flex;
    flex-direction: row;

    .nav-link {
        background: #F1F2F3;
        padding: 18px 30px;
        border-radius: 0;
        text-align: left;
        text-transform: uppercase;
        color: #554E4E;
        margin-bottom: 10px;
        flex-wrap: wrap;
        display: flex;
        align-items: center;

        span {
            position: relative;
            top: 4px;
            color: #fff;
            border: 1px solid #C4C4C4;
            border-radius: 5px;
            height: 30px;
            width: 30px;
            margin-right: 10px;


            .mat-icon {
                position: relative;
                top: 2px;
                background-color: #C4C4C4;
                left: 2px;
                border-radius: 5px;
                font-size: 24px;
            }
        }

        p {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            position: relative;
            top: 0;
        }

        &.active {
            background-color: $color-pink;
            padding: 18px 30px;
            border-radius: 0;
            text-align: left;

            span {
                display: inline-block;
                position: relative;
                top: 0px;
                margin-right: 10px;
                border: 1px solid #fff;
                height: 30px;
                width: 30px;
                border-radius: 5px;
                background: transparent;

                .mat-icon {
                    font-size: 26px;
                    position: relative;
                    top: 1px;
                    left: 1px;
                    background-color: transparent;
                    border-radius: 4px;
                }
            }

            p {
                color: #fff;
                position: relative;
                margin-bottom: 0;
            }
        }
    }

    .bg-profile {
        background: #F1F2F3;
        padding: 20px 30px;
    }

    @media (max-width: 991px) {
        display: block;

        .w-25 {
            width: 100% !important;
        }

        .w-75 {
            width: 100% !important;
        }

        .ms-4 {
            margin-left: 0 !important;
        }
    }
}

.w-75 {
    width: 75%;
}

.profile-img {
    img {
        background-color: #fff;
        border-radius: 50%;
        border: 5px solid #8B96A5;
        width: 15%;
        margin: 0 auto;
        position: relative;
        height: 130px;
        object-fit: cover;
        display: block;

        @media (max-width: 1400px) {
            width: 17%;
        }

        @media (max-width: 1200px) {
            width: 21%;
        }

        @media (max-width: 767px) {
            width: 30%;
        }

        @media (max-width: 530px) {
            width: 31%;
        }

        @media (max-width: 470px) {
            width: 36%;
        }

        @media (max-width: 420px) {
            width: 41%;
        }

        @media (max-width: 330px) {
            width: 55%;

        }
    }

    .mat-icon {
        font-size: 104px;
        height: 100px;
        width: 100px;
        color: #8B96A5;
    }
}

.edit-icon {
    background-color: #fff;
    border-radius: 50%;
    border: 3px solid #8B96A5;
    position: absolute;
    right: 43%;
    top: 91px;
    height: 35px;
    width: 35px;

    @media (max-width: 1180px) {
        right: 41%;
        top: 94px;
    }

    @media (max-width: 767px) {
        right: 38%;
        top: 94px;
    }

    @media (max-width: 530px) {
        right: 36%;
    }

    mat-icon {
        position: relative;
        top: 3px;
        left: 6px;
        font-size: 18px;
        color: $color-pink;

        input {
            width: 0;
            border: none;
        }
    }
}

.address-row {
    row-gap: 30px;
}

.address-card {
    background-color: $white-bg;
    padding: 18px;
    position: relative;
    margin-bottom: 15px;
    height: 100%;

    h3 {
        color: #344054;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    p {
        color: #667085;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .btn-close {
        position: absolute;
        right: 7px;
        top: 9px;
        font-size: 12px;

        i {
            display: none;
        }
    }

    .action-btn {
        display: flex;
        justify-content: end;
        position: absolute;
        right: 10px;
        bottom: 10px;

        .edit-btn {
            background-color: #F3F3F3;
            color: #6792FF;
            padding: 3px 6px;
            align-items: center;
            display: flex;

            mat-icon {
                position: relative;
                top: 3px;
            }
        }

        .default-btn {
            background-color: #F3F3F3;
            margin-left: 5px;
            padding: 3px 6px;
            display: flex;

            &.active {
                span {
                    color: $color-pink;
                }
            }

            span {
                color: #98A2B3;
                font-size: 14px;
                position: relative;
                top: 5px;
            }
        }
    }
}

.passes-section {
    .session-box {
        background: transparent;

        .session-textbox {
            position: relative;

            form {
                .session-search {
                    input {
                        padding: 10px 150px 10px 43px;

                        @media (max-width: 1180px) {
                            padding: 10px 100px 10px 43px;
                        }

                        @media (max-width: 767px) {
                            padding: 10px 83px 10px 30px;
                        }
                    }

                    .date {
                        i {
                            position: absolute;
                            left: 65%;
                            top: 12px;
                            border-left: 1px solid #cccc;
                            padding-left: 30px;
                            cursor: pointer;

                            @media (max-width: 1180px) {
                                left: 65%;
                                padding-left: 16px;
                            }

                            @media (max-width: 767px) {
                                padding-left: 13px;
                            }
                        }
                    }

                    .filter {
                        i {
                            position: absolute;
                            left: 82%;
                            top: 12px;
                            border-left: 1px solid #cccc;
                            padding-left: 30px;
                            cursor: pointer;

                            @media (max-width: 991px) {
                                padding-left: 20px
                            }

                            @media (max-width: 767px) {
                                padding-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .session-section {
        background: #F3F3F3;
        padding: 20px 19px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        text-wrap: nowrap;

        @media (max-width: 450px) {
            display: block;
        }

        .session-attend {
            border-right: 1px solid #ddd;
            padding: 0px 35px 10px 5px;

            @media (max-width: 1200px) {
                padding: 0px 13px 10px 5px;
            }

            @media (max-width: 1130px) {
                padding: 0px 10px 10px 8px;
            }

            @media (max-width: 990px) {
                padding: 0 140px 10px 8px;
            }

            @media (max-width: 767px) {
                padding: 0 50px 10px 8px;
            }

            @media (max-width: 500px) {
                padding: 0 24px 10px 8px;
            }

            @media (max-width:450px) {
                padding: 0 9px 10px 8px;
                display: block;
                border-right: none;
            }

            &:last-child {
                border-right: none;
            }

            @media (max-width: 767px) {
                .ps-3 {
                    padding-left: 0 !important;
                    padding-top: 10px;
                }

                .border-start {
                    border: none !important
                }

                .pe-3 {
                    padding-right: 0 !important;
                }
            }

            p {
                color: #344054;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 5px;
            }

            h3 {
                color: #CC257A;
                font-size: 26px;
                font-weight: 600;
                margin-bottom: 0;

                @media (max-width: 1400px) {
                    font-size: 22px;
                }
            }
        }
    }

    .passes-ticket {
        background: #fff;
        padding: 20px;
        border-left: 6px solid #FFBF00;
        margin-bottom: 10px;

        &.expired {
            border-left: 6px solid #F54E52 !important;
        }

        &.absent {
            border-left: 6px solid #F54E52 !important;
        }

        &.present {
            border-left: 6px solid #73D063 !important;
        }

        @media (max-width: 990px) {
            .d-flex {
                display: block !important;
            }
        }

        h2 {
            color: $color-pink;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 3px;
        }

        h4 {
            color: $color-pink;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 5px
        }

        .passes-cheatbox {
            padding: 20px;
            background-color: $color-pink;
            width: 100%;

            @media (max-width: 990px) {
                margin-top: 7px;
            }

            @media (max-width: 767px) {
                margin-top: 10px;
            }


            h2 {
                color: $white-color;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 1px;
                padding-bottom: 15px;

                @media (max-width: 1360px) {
                    font-size: 14px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    padding-bottom: 8px;
                }

                span {
                    font-size: 20px;
                    font-weight: 600;
                    color: $white-color;
                    float: right;

                    @media (max-width: 1360px) {
                        font-size: 16px;
                        float: none;
                    }

                    @media (max-width: 767px) {
                        font-size: 15px;
                        float: none;
                    }
                }
            }

            .date-passes {
                color: $white-color;
                display: flex;
                margin-top: 15px;

                @media (max-width:1140px) {
                    display: block;
                }

                @media (max-width: 990px) {
                    display: flex;
                }

                @media (max-width: 767px) {
                    display: block;
                }

                label {
                    display: inline-block;
                    font-size: 14px;
                    width: 40%;

                    @media (max-width: 1400px) {
                        width: 33%;
                    }

                    @media (max-width: 1140px) {
                        width: 100%;
                    }

                    @media (max-width: 990px) {
                        width: 50%;
                    }
                }

                ul {
                    display: flex;
                    font-size: 15px;
                    margin-bottom: 0;
                    padding-left: 0px;
                    justify-content: space-between;
                    width: 60%;

                    @media (max-width: 1400px) {
                        width: 75%;
                    }

                    @media (max-width: 1180px) {
                        display: block;
                    }

                    @media (max-width: 1140px) {
                        width: 100%;
                    }

                    @media (max-width: 990px) {
                        width: 50%;
                    }

                    @media (max-width: 767px) {
                        display: flex;
                    }

                    li {
                        i {
                            margin-right: 5px;
                        }

                        &:last-child {
                            position: relative;
                            bottom: 3px;

                            &::before {
                                background-color: #fff;
                                border-radius: 50%;
                                content: "";
                                margin: 8px;
                                height: 4px;
                                width: 0px;
                                position: relative;
                                display: inline-block;
                                top: 4px;

                                @media (max-width: 1140px) {
                                    margin: 10px 0;
                                }
                            }
                        }

                    }
                }

            }
        }

        @media (max-width: 767px) {
            .d-flex {
                display: block !important;
            }
        }

        .pass-view-btn {
            background-color: #E4E4E7;
            border-radius: 50%;
            min-height: 40px;
            max-height: 40px;
            margin-left: 2px;
            width: 40px;
            position: relative;
            // top: 50px;
            left: 10px;
            cursor: pointer;

            @media (max-width: 990px) {
                top: 10px;
            }

            label {
                .rotate-btn {
                    transform: rotate(90deg);
                }
            }

            @media (max-width: 1180px) {
                min-height: 40px;
                max-height: 40px;
                padding: 9px;
            }

            @media (max-width: 767px) {
                min-height: 38px;
                max-height: 38px;
                top: 0;
                left: 0;
                margin-top: 10px;
            }

            i {
                color: $color-pink;
                font-size: 18px;
                position: relative;
                top: 4px;
                left: 0px;

                @media (max-width: 1180px) {
                    left: -8px;
                    top: -7px;
                }

                @media (max-width: 767px) {
                    left: -9px;
                }
            }
        }
    }

    .passes-report {
        background: $white-bg;
        padding: 25px;
        margin-top: 15px;

        p {
            color: #554E4E;
            font-weight: 500;
        }

        .mb-8 {
            margin-bottom: 8px;
        }

        a {
            text-decoration: underline;
            font-weight: 700;
        }
    }

    .attend-list {
        a {
            color: #98A2B3;
            font-size: 14px;
            font-weight: 600;
            text-align: right;
            padding: 10px 0;
            display: flex;
            justify-content: end;
            text-decoration: none;
        }

        h3 {
            color: #554E4E;
            font-size: 20px;
            font-weight: 700;
            padding: 10px 0;
        }

        ul {
            height: 100%;
            max-height: 700px;
            overflow-y: auto;
            list-style-type: none;
            padding-left: 0px;

            li {
                margin-top: 10px;

                .session-list {
                    background-color: #D8FFDE;
                    padding: 15px;
                    border: 1px solid #64D576;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    row-gap: 15px;

                    @media (max-width: 550px) {}

                    .day-session {
                        h5 {
                            color: #4A4A4A;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 1px;
                        }

                        p {
                            font-size: 14px;
                            color: #4A4A4A;
                            font-weight: 500;
                            margin-bottom: 0px;

                            i {
                                margin-right: 10px;
                            }
                        }

                    }

                    .btn {
                        min-width: 120px;
                        text-align: center;
                        justify-content: center;
                        padding: 0.625em 0.8125em;
                    }

                    .report-btn {
                        .btn-primary {
                            background: #FFC3C3;
                            color: #FF0000;
                            letter-spacing: 1px;
                            font-size: 16px;
                            border: 1px solid #FF0000;
                            padding: 0.625em 0.8125em;

                            @media (max-width:767px) {}

                            @media (max-width: 550px) {
                                height: auto;
                                font-size: 14px;
                            }
                        }
                    }

                    .reported {
                        .btn-primary {
                            background: #6c757d;
                            border: 1px solid #6c757d;
                            color: #fff;
                        }
                    }

                    .attend-btn {
                        .btn-primary {
                            background: #FFFFFF;
                            color: #64D576;
                            border: 1px solid #64D576;
                            letter-spacing: 1px;
                            font-size: 16px;
                            padding: 0.625em 0.8125em;

                            @media (max-width: 550px) {
                                height: auto;
                                font-size: 14px;
                            }
                        }
                    }

                    .early-cancel-btn {
                        .btn-primary {
                            background: #cc257a;
                            color: #fff;
                            border: 1px solid #cc257a;
                            letter-spacing: 1px;
                            font-size: 16px;
                            padding: 0.625em 0.8125em;

                            @media (max-width: 550px) {
                                height: auto;
                                font-size: 14px;
                            }
                        }
                    }

                    .top-12 {
                        position: relative;
                        top: 12px;

                        @media (max-width: 767px) {
                            top: 0px;
                        }

                        @media (max-width: 550px) {
                            top: 12px;
                        }
                    }

                    .d-flex {
                        @media (max-width: 767px) {}

                        @media (max-width: 550px) {
                            display: flex !important;

                            .me-3 {
                                margin-right: 5px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*Start report session model CSS*/
.reportsession-section {
    margin: 0 !important;

    @media (max-width: 450px) {
        .w-50 {
            width: 100% !important;
        }
    }

    h3 {
        color: #F5141A;
        font-size: 16px;
        font-weight: 600;
    }

    .report-session-box {
        background: $color-pink;
        padding: 20px;
        width: 100%;
        margin-bottom: 12px;
        margin-top: 10px;

        h2 {
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 5px;
        }

        p {
            color: $white-color;
            font-weight: 300;
            font-size: 16px;
            margin-bottom: 0;
            margin-top: 10px;

            i {
                margin-right: 10px;
            }
        }
    }

    span {
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
    }

    .purchase-form {
        form {
            textarea {
                border-radius: 0;
                border: 1px solid #E4E4E7;
                font-size: 14px;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}

.verification-form {

    .form-label {
        color: #CC257A;
    }

    .w-10 {
        width: 12%;
        border-radius: 3px;
        padding: 13px 21px;
        font-size: 24px;

        &:focus {
            box-shadow: none;
            border-color: $color-pink;
        }
    }
}

// About us CSS

.about-content {
    padding: $section-padding;
    padding-bottom: 5em;

    .about-text {
        height: 450px;
        overflow-y: auto;

        @media (max-width: 800px) {
            height: auto;
            overflow-y: hidden;
        }

        .headingtext {
            @include heading;
            text-align: left;
        }
    }

    .image-box {
        background: $white-bg;
        box-shadow: 0px 0px 14px 0px rgba(194, 209, 224, 0.50);
        padding: 1em;

        @include lg {
            margin-bottom: 2em;
        }
    }


    p {
        text-align: left;
        color: $color-grey;
        font-size: 0.9em;
        letter-spacing: 1.52px;
        word-break: break-word;
    }

    &.about-bg-grey {
        background-color: $light-grey-bg;

        .row {
            @include lg {
                flex-direction: column-reverse;
            }
        }
    }
}

.about-images {
    .image-gallery {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        @include lg {
            justify-content: center
        }

        .image-box {
            background: $white-bg;
            box-shadow: 0px 0px 14px 0px rgba(194, 209, 224, 0.50);
            padding: 1em;
            width: 100%;
            min-width: 233px;
            max-width: 233px;
            height: 200px;
            object-fit: cover;

            @include lg {
                max-width: 170px;
            }

        }

    }
}

.purchase-box {
    background-color: $white-color;
    padding: 14px;
    border-left: 5px solid #FFBF00;
    display: flex;
    flex-flow: row wrap;

    @media (max-width: 767px) {
        display: block;
    }

    .purchase-img {
        flex: 0 1 calc(21% - 0px);

        img {
            border: 3px solid #98A2B3;
            width: 100%;

            @media (max-width: 767px) {
                margin-bottom: 10px;
            }
        }
    }
}

/* coupon code style start*/
.coupon_main {
    background: rgba(238, 51, 49, 1);
    min-height: 44px;
    border-radius: 3px;
    overflow: hidden;
    filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5));
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    -webkit-mask-image: radial-gradient(circle at 10px 50%, transparent 6px, rgba(238, 51, 49, 1) 5.5px);
    -webkit-mask-position: -10px;
    width: fit-content;
    min-width: 200px;
    gap: 7px;

    .left-part {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        max-width: 110px;
    }

    .right-part {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        min-width: 120px;

        .percent {
            font-family: Poppins;
            color: #fff;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1px;

            .value {
                font-size: 20px;
                font-weight: 700;
                line-height: 20px;
            }

            .off_value {
                font-size: 13px;
                font-style: italic;
                font-weight: 600;
                line-height: 13px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: flex-start;
                gap: 2px;
            }
        }
    }

    .code {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
        width: 100%;
    }

}

/* coupon code style end*/

/*Calender css*/
.ngb-dp-header {
    background-color: #fff !important;
}

ngb-datepicker.dropdown-menu {
    border-radius: 0;
    width: 320px;
    padding: 21px !important;
}

ngb-datepicker-month {
    display: block;
    width: 100% !important;
}

.ngb-dp-weekdays {
    background-color: #fff !important;
}

.ngb-dp-weekday,
.ngb-dp-week-number {
    font-style: normal !important;
}

ngb-datepicker-navigation-select>.form-select {
    border: none;
}

.ngb-dp-weekday {
    color: #6E6A7C !important;
}

.ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
    margin: 2px 3px;
}

.ngb-dp-day {
    cursor: pointer;
    color: #0E0518 !important;
    background: #fff !important;
    border: 1px solid #ccc !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    font-size: 14px;
    padding: 0 0px;
}

.session-box .session-textbox form .custom-day.faded {
    background-color: #98A2B3 !important;
}

.session-box .session-textbox form .custom-day {
    padding: 5px 5px;
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.session-box .session-textbox form .custom-day.range {
    color: #fff !important;
    background-color: #4A4A4A !important;
    border-radius: 50%;
}

.ngb-dp-arrow {
    display: flex;
    flex: 1 1 auto;
    padding-right: 0px;
    padding-left: 0;
    margin: 0px !important;
    height: 30px !important;
    background: #EEEEEE;
    min-width: 0px !important;
    max-width: 22px !important;
    border-radius: 5px;
}

.ngb-dp-navigation-chevron {
    width: 12px !important;
    right: 7px;
    position: relative;
    color: #6E6A7C;
    height: 12px !important;

}

.ngb-dp-arrow-next .ngb-dp-navigation-chevron {
    margin-left: 0 !important;
    margin-right: 0 !important;
    position: relative;
    left: 4px !important;
}

.ngb-dp-arrow-btn {
    padding: 3px;
}

.session-box .session-textbox form .custom-day:hover {
    background-color: #98A2B3 !important;
}

.para-heading h1::before {
    bottom: -13px
}

.para-heading h1::after {
    bottom: 0px;
}

.privacy {
    h3 {
        color: #554E4E;
        font-size: 20px;
        font-weight: 600;
    }

    p {
        font-size: 14px;
        color: #4A4A4A;
        opacity: 0.9;
        letter-spacing: 1px;
        line-height: 20px;
        word-break: break-word;
    }

    ul {
        li {
            font-size: 14px;
            color: #4A4A4A;
            opacity: 0.9;
            letter-spacing: 1px;
            line-height: 25px;

            &::before {
                content: "";
                background: #CC257A;
                width: 10px;
                height: 10px;
                margin-right: 10px;
                position: relative;
                display: inline-block;
                border-radius: 10px;
            }
        }
    }

    ol {
        li {
            font-size: 14px;
            color: #4A4A4A;
            opacity: 0.9;
            letter-spacing: 1px;
            line-height: 20px;
            white-space: nowrap;

            &::before {
                content: "";
                margin-right: 10px;
                position: relative;
                display: inline-block;

            }

            &::marker {
                color: #CC257A;
                font-weight: 600;
            }
        }
    }

    i {
        font-weight: 700;
    }
}

.payment-form .select2-container {
    border-radius: 0.125em;
    color: #98A2B3;
    width: 100% !important;
}

.payment-form .select2-container--default .select2-selection--single {
    border: 1px solid #dee2e6;
    height: 40px;
    border-radius: 0;
}

.payment-form .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #667085;
    line-height: 38px;
}

.payment-form .select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-left: -10px;
    margin-top: 3px;
}

.text-danger {
    font-size: 14px;
}

.form-control:focus {
    border-color: #98a2b3;
    box-shadow: none;
}


.normal .toast-container>div {
    background-position: 12px 20px !important;
}

.checkout-table .card-one {
    white-space: nowrap;

}

// purchase passe extension

.pass-row {
    display: flex;
    align-items: center;
    max-width: 320px;
    border-bottom: 2px dashed #fff;

    @media (max-width: 990px) {
        max-width: 100%;
    }
}

.pass-row h2 {
    flex: 1;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pass-row span {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
    padding-bottom: 15px;
}

//   Address card css in the account section
.address-card button.btn-close {
    border: 2px solid #fd3f3f;
    padding: 0px;
    text-align: center;
    border-radius: 100%;
    opacity: 1;
    width: 20px;
    height: 20px;
    background-size: contain;
    cursor: pointer;
    --bs-btn-close-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FD3F3F' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M8 8 L24 24 M8 24 L24 8'/%3E%3C/svg%3E");
}

.address-card button.btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity);
}

.address-card .form-check-input:checked[type=radio] {
    opacity: 1;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.267 6.87773C11.6291 6.54617 11.6537 5.98389 11.3222 5.62187C10.9906 5.25984 10.4283 5.23514 10.0663 5.56671L6.78446 8.57242L5.93369 7.79323C5.57166 7.46166 5.00939 7.48636 4.67782 7.84838C4.34625 8.21041 4.37095 8.77268 4.73298 9.10425L6.1841 10.4333C6.52386 10.7445 7.04506 10.7445 7.38482 10.4333L11.267 6.87773Z' fill='%23CC257A'/%3e%3c/svg%3e");
}

.address-card .form-check-input:checked {
    background-color: #ffffff;
    border-color: #CC257A;
}

// Shoping cart remove item button

.shopping-cart .btn-close {
    border: 2px solid #fd3f3f;
    padding: 0px;
    text-align: center;
    border-radius: 100%;
    opacity: 1;
    width: 20px;
    height: 20px;
    background-size: contain;
    cursor: pointer;
    --bs-btn-close-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FD3F3F' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M8 8 L24 24 M8 24 L24 8'/%3E%3C/svg%3E");
}

.shopping-cart .btn-close:focus {
    outline: 0;
    box-shadow: 2px 2px 3px #eee;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .card-one h6 span {
        max-width: 250px;
        text-wrap: wrap;
        display: inline-block;
    }

    .card-one h6 img {
        vertical-align: top;
    }
}

@media screen and (max-width: 1440px) {
    .card-body .btn-dark {
        padding: 10px 12px;
        font-size: 26px;
    }
}

.back_to_top {
    background-color: #4A4A4A;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    border: 1px double #4A4A4A;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: 30px;
}

.row.headings {
    @include sm {
        padding-left: 8px;
        padding-bottom: 0;
    }
}

.mt-sm-0 {
    margin-top: 0;
}

.record-toggle {
    @include sm {
        justify-content: center;
    }
}

.paggination-page {
    @include sm {
        justify-content: center !important;
    }
}

.paggination-page {
    @include md {
        margin-top: 30px;
    }
}

.mt-c-4 {
    padding-top: 60px;
}

@media screen and (max-width: 990px) {
    .mt-c-4 {
        padding-top: 0px;
    }
}

@media screen and (max-width: 418px) {
    .card-one h6 span {
        max-width: 250px;
        text-wrap: wrap;
        display: inline-block;
    }

    .card-one h6 img {
        vertical-align: top;
    }

}

@media (max-width: 550px) {
    .modal-header {

        h1::before,
        .h1::before {
            bottom: 11px;
        }

        h1::after,
        .h1::after {
            bottom: 2px;
        }
    }
}

@media (max-width: 450px) {
    .class-payment {
        ul {
            padding-left: 0 !important;

            li {
                display: block !important;

                .w-25 {
                    width: auto !important;
                }
            }
        }
    }
}

.event-link{
    color: $color-pink;
    cursor: pointer !important;
    word-break: break-all;
}